<script>

import axios from 'axios'
import Modal from '../Modal'
import HelpButton from '../HelpButton'

export default {
    name: 'level4',
    components: { Modal, HelpButton },
    props: {
        sublevel: Number,
    },
    methods: {
        selectVine() {
            if (this.selected === 'vine') {
                this.selected = null
            } else {
                this.selected = 'vine'
            }
        },
        selectSoda() {
            if (this.selected === 'soda') {
                this.selected = null
            } else {
                this.selected = 'soda'
            }
        },
        selectGlass(index) {
            if (this.selected === 'glass_' + index) {
                this.selected = null
                return
            }
            if (this.selected === 'vine') {
                const glass = this.glasses[index]
                if (glass.size * this.denominator - glass.vine - glass.soda > 0) {
                    this.vine_sound.play()
                }
                glass.vine += glass.size * this.denominator - glass.vine - glass.soda
                this.selected = null
                setTimeout(() => {
                    this.checkSolutions()
                }, 800)
                return
            }
            if (this.selected === 'soda') {
                const glass = this.glasses[index]
                if (glass.size * this.denominator - glass.vine - glass.soda > 0) {
                    this.soda_sound.play()
                }
                glass.soda += glass.size * this.denominator - glass.vine - glass.soda
                this.selected = null
                this.soda_active = true
                setTimeout(() => {
                    this.soda_active = false
                    this.checkSolutions()
                }, 800)
                return
            }
            if (this.selected !== null && this.selected.indexOf('glass_') === 0) {
                const selected_glass = this.glasses[parseInt(this.selected.substr(6))]
                const glass = this.glasses[index]
                const left = glass.size * this.denominator - glass.vine - glass.soda
                const ratio = (selected_glass.vine + selected_glass.soda) / left
                var vine_to_pour = selected_glass.vine
                var soda_to_pour = selected_glass.soda
                if (ratio > 1) {
                    vine_to_pour = selected_glass.vine / ratio
                    soda_to_pour = selected_glass.soda / ratio
                }
                selected_glass.vine -= vine_to_pour
                selected_glass.soda -= soda_to_pour
                glass.vine += vine_to_pour
                glass.soda += soda_to_pour
                if (vine_to_pour > 0 || soda_to_pour > 0) {
                    this.vine_sound.play()
                }
                this.selected = null

                setTimeout(() => {
                    this.checkSolutions()
                }, 800)

                return
            }
            this.selected = 'glass_' + index
        },
        toSink() {
            if (this.selected !== null && this.selected.indexOf('glass_') === 0) {
                const glass = this.glasses[parseInt(this.selected.substr(6))]
                if (glass.vine > 0 || glass.soda > 0) {
                    this.sink_sound.play()
                }
                glass.vine = 0
                glass.soda = 0
                this.selected = null
            }
        },
        getClasses(glass_idx) {
            const glass = this.glasses[glass_idx]
            const classes = {
                size_2: glass.size==2,
                size_3: glass.size==3,
                size_5: glass.size==5,
                selected: this.selected=='glass_'+glass_idx,
                vine: glass.vine > 0 && glass.soda === 0 || (glass.vine === 0 && glass.soda === 0 && glass.previous_type === 'vine'),
                soda: glass.soda > 0 && glass.vine === 0 || (glass.vine === 0 && glass.soda === 0 && glass.previous_type === 'soda'),
                mix: glass.soda > 0 && glass.vine > 0 || (glass.vine === 0 && glass.soda === 0 && glass.previous_type === 'mix'),
            }
            if (glass.soda > 0 && glass.vine > 0) {
                glass.previous_type = 'mix'
            } else if (glass.soda > 0) {
                glass.previous_type = 'soda'
            } else if (glass.vine > 0) {
                glass.previous_type = 'vine'
            } else {
                glass.previous_type = null
            }
            return classes
        },
        getStyle(glass_idx) {
            const glass = this.glasses[glass_idx]
            var pos = 25
            switch (glass.size) {
                case 2:
                    pos += (2 - (glass.vine + glass.soda) / this.denominator) * 28
                    break;
                case 3:
                    pos += (3 - (glass.vine + glass.soda) / this.denominator) * 28
                    break;
                case 5:
                    pos += (5 - (glass.vine + glass.soda) / this.denominator) * 22
                    break;
            }
            if (glass.vine + glass.soda === 0) {
                pos += 10
            }
            return {
                'backgroundPositionY': pos + 'px'
            }
        },
        setLevel() {
            this.message = this.levels[this.level].message
            this.glasses = this.levels[this.level].glasses
            this.selected = null
        },
        checkSolutions() {
            this.levels[this.level].solutions.forEach((solution_set) => {
                this.checkSolution(solution_set)
            })
        },
        checkSolution(solution_set) {
            for (var glass_idx = 0; glass_idx < solution_set.length; glass_idx++) {
                const sol = solution_set[glass_idx]
                const glass = this.glasses[glass_idx]
                if (sol !== null && (sol.vine !== glass.vine || sol.soda !== glass.soda)) {
                    return
                }
            }
            this.$refs['level-completed-modal'].openModal()

            const email = localStorage.getItem('email')
            const sublevel = this.level === this.levels.length - 1 ? 0 : this.level + 1
            this.$emit('setSublevel', 4, sublevel)
            axios.post('/api/sublevel_completed', {'level': 4, 'email': email, 'sublevel': sublevel})

            if (this.level === this.levels.length - 1) {
                this.$emit('levelCompleted')
            }
        },
        next() {
            this.$refs['level-completed-modal'].closeModal()
            if (this.level === this.levels.length - 1) {
                this.$emit('backToDashboard')
            } else {
                this.level++
                this.setLevel()
            }
        },
        loadSound(name) {
            return new Audio(require(`../sound/${name}.mp3`))
        },
    },
    created() {
        this.level = this.sublevel
    },
    mounted() {
        this.$refs['modal'].openModal()
        this.number_of_levels = this.levels.length
        this.setLevel()
        this.sink_sound = this.loadSound('sink')
        this.soda_sound = this.loadSound('soda')
        this.vine_sound = this.loadSound('vine')
    },
    data()
    {
        return {
            selected: null,
            level: 0,
            glasses: [],
            message: '',
            soda_active: false,
            number_of_levels: 0,
            sink_sound: null,
            soda_sound: null,
            vine_sound: null,
            denominator: 2*2*2*2*3*3*3*3*5*5*5*5,
            levels: [
                {
                    message: 'Töltsd meg a 3dl-es poharat szódával,\na 2 dl-eset borral.',
                    glasses: [
                        { size: 2, vine: 0, soda: 0 },
                        { size: 3, vine: 3 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 0 }
                    ],
                    solutions: [[
                        { vine: 2 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 0 },
                        { vine: 0, soda: 3 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                    ]]
                },
                {
                    message: 'Tölts a 3dl pohárba 2dl bort!',
                    glasses: [
                        { size: 2, vine: 0, soda: 0 },
                        { size: 3, vine: 0, soda: 0 }
                    ],
                    solutions: [[
                        null,
                        { vine: 2 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 0 },
                    ]]
                },
                {
                    message: 'Készíts a 2dl-es pohárba egy kisfröccsöt!',
                    glasses: [
                        { size: 2, vine: 0, soda: 0 },
                        { size: 3, vine: 0, soda: 0 }
                    ],
                    solutions: [[
                        { vine: 1 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 1 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                        null,
                    ]]
                },
                {
                    message: 'Készíts egy sportfröccsöt és egy kisfröccsöt!',
                    glasses: [
                        { size: 2, vine: 0, soda: 0 },
                        { size: 5, vine: 0, soda: 0 }
                    ],
                    solutions: [[
                        { vine: 1 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 1 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                        { vine: 1 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 4 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                    ]]
                },
                {
                    message: 'Készíts egy háziúrt és egy hosszúlépést!',
                    glasses: [
                        { size: 3, vine: 0, soda: 0 },
                        { size: 5, vine: 0, soda: 0 }
                    ],
                    solutions: [[
                        { vine: 1 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 2 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                        { vine: 4 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 1 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                    ]]
                },
                {
                    message: 'Készíts egy házmestert, egy sportfröccsöt\nés egy hosszúlépést!',
                    glasses: [
                        { size: 3, vine: 0, soda: 0 },
                        { size: 5, vine: 0, soda: 0 },
                        { size: 5, vine: 0, soda: 0 }
                    ],
                    solutions: [
                        [
                            { vine: 1 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 2 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                            { vine: 1 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 4 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                            { vine: 3 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 2 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                        ],
                        [
                            { vine: 1 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 2 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                            { vine: 3 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 2 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                            { vine: 1 * 2*2*2*2*3*3*3*3*5*5*5*5, soda: 4 * 2*2*2*2*3*3*3*3*5*5*5*5 },
                        ],
                    ]
                }
            ]
        }
    },
}
</script>

<template lang="pug">
div
    modal(ref="modal" :width="500" :marginTop="200")
        .px-5.py-1.text-light
            p 1. Palackból bort tölthetsz.
            p 2. Szikvizes palackból szódát tölthetsz.
            p 3. A csapba kiöntheted a pohár tartalmát.
            p 4. Amire először kattintasz abból töltesz, amire másodszor abba töltöd.
            button.d-block.mx-auto.my-5.px-5.btn(@click="() => $refs.modal.closeModal()") BEZÁRÁS
    modal(ref="level-completed-modal" :width="300" :marginTop="300")
        .text-center.text-light(v-if="level===5") Profin keversz bármilyen fröccsöt! Közelebb kerültél a Fröccs titkához!
        .text-center.text-light(v-else) Ez megvan! Mehet a következő szint?
        button.d-block.mx-auto.mt-4.px-5.btn(@click="next()") MEHET
        .pt-1
    .content.rounded.mt-4
        .container
            .indicator.d-inline.rounded.py-1.px-3 {{level+1}}/{{number_of_levels}}
            .vine-bottle(:class="{selected: selected=='vine'}" role="button" @click="selectVine")
            .soda-bottle(:class="{selected: selected=='soda', active: soda_active}" role="button" @click="selectSoda")
            .glass-container.d-flex.justify-content-center.align-items-end
                .glass(v-for="(glass, idx) in glasses" :class="getClasses(idx)" :style="getStyle(idx)" role="button" @click="()=>selectGlass(idx)")
                    .empty-glass
                    .text-center.text-light.size {{glass.size}} dl
            .sink(@click="toSink" role="button")
            .message.d-flex.align-items-center.justify-content-center.text-center {{message}}
    .d-flex.justify-content-end.my-4
        help-button(hint="1. Palackból bort tölthetsz.<br>2. Szikvizes palackból szódát tölthetsz.<br>3. A csapba kiöntheted a pohár tartalmát.<br>4. Amire először kattintasz abból töltesz, amire másodszor abba töltöd." :width="400")
</template>

<style scoped>
.content {
    width: 900px;
    height: 562.5px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('../img/glass/background.png');
}
.container {
    position: absolute;
}
.indicator {
    position: absolute;
    top: 16px;
    left: 824px;
    font-size: 20px;
    background-color: #a0a0a0;
}
.vine-bottle, .soda-bottle, .glass-container {
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
}
.vine-bottle {
    top: 83px;
    left: 51px;
    width: 88px;
    height: 241px;
    background-image: url('../img/glass/vine.png');
}
.vine-bottle.selected {
    background-image: url('../img/glass/vine_selected.png');
}
.soda-bottle {
    top: 138px;
    left: 118px;
    width: 106px;
    height: 200px;
    background-image: url('../img/glass/soda.png');
}
.soda-bottle.selected {
    background-image: url('../img/glass/soda_selected.png');
}
.soda-bottle.active {
    background-image: url('../img/glass/soda_active.png');
}
.glass-container {
    top: 44px;
    left: 260px;
    width: 400px;
    height: 260px;
}
.glass .empty-glass {
    background-size: 100%;
    background-repeat: no-repeat;
}
.glass.size_2 .empty-glass {
    width: 76px;
    height: 142px;
    background-image: url('../img/glass/glass2.png');
}
.glass.size_2.selected .empty-glass {
    background-image: url('../img/glass/glass2_selected.png');
}
.glass.size_3 .empty-glass {
    width: 84px;
    height: 169px;
    background-image: url('../img/glass/glass3.png');
}
.glass.size_3.selected .empty-glass {
    background-image: url('../img/glass/glass3_selected.png');
}
.glass.size_5 .empty-glass {
    width: 104px;
    height: 194px;
    background-image: url('../img/glass/glass5.png');
}
.glass.size_5.selected .empty-glass {
    background-image: url('../img/glass/glass5_selected.png');
}
.glass {
    background-repeat: no-repeat;
    background-position-x: 19px;
    transition: background-position-y 0.6s ease-in-out, background-image 0.6s ease-in-out;
}
.glass.size_2 {
    width: 76px;
    height: 92px;
    background-size: 49%;
    background-position-y: 25px;
}
.glass.size_3 {
    width: 84px;
    height: 118px;
    background-size: 54%;
    background-position-y: 25px;
}
.glass.size_5 {
    width: 104px;
    height: 146px;
    background-size: 63.5%;
    background-position-y: 25px;
}
.glass.size_2.vine {
    background-image: url('../img/glass/vine2.png');
}
.glass.size_2.soda {
    background-image: url('../img/glass/soda2.png');
}
.glass.size_2.mix {
    background-image: url('../img/glass/mix2.png');
}
.glass.size_3.vine {
    background-image: url('../img/glass/vine3.png');
}
.glass.size_3.soda {
    background-image: url('../img/glass/soda3.png');
}
.glass.size_3.mix {
    background-image: url('../img/glass/mix3.png');
}
.glass.size_5.vine {
    background-image: url('../img/glass/vine5.png');
}
.glass.size_5.soda {
    background-image: url('../img/glass/soda5.png');
}
.glass.size_5.mix {
    background-image: url('../img/glass/mix5.png');
}
.glass .size {
    margin-top: -26px;
}
.sink {
    position: absolute;
    top: 150px;
    left: 680px;
    width: 220px;
    height: 210px;
}
.message {
    position: absolute;
    top: 430px;
    left: 280px;
    width: 340px;
    height: 84px;
    white-space: pre;
}
.btn {
    margin-bottom: 20px;
    color: #fff;
    background-color: #C19327;
}
</style>