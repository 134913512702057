<script>

import Modal from './Modal'

export default {
    name: 'help-button',
    components: { Modal },
    props: {
        'hint': String,
        'video_id': String,
        width: {
            type: Number,
            default: 275,
        },
    },
    computed: {
        modalMarginTop() {
            return this.video_id ? 80 : 300
        },
        modalWidth() {
            return this.video_id ? 700 : this.width
        }
    },
    methods: {
        help() {
            this.$refs.modal.openModal()
        },
        close() {
            this.$refs.modal.closeModal()
        }
    }
}
</script>

<template lang="pug">
div
    modal(ref="modal" :width="modalWidth" :marginTop="modalMarginTop" :showLogo="!video_id")
        .video(v-if="video_id")
            iframe.mt-5.d-block.mx-auto(width="560" height="315" :src="`https://www.youtube.com/embed/${video_id}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-if="video_id")
            .d-flex.justify-content-between.mt-4.mb-5.mx-auto(:style="{width: '560px'}")
                img.small-logo(src="./img/logo_dark.png")
                div
                    button.btn.close-popup(@click="close") BEZÁRÁS
        .text(v-else)
            .hint.text-center(v-html="hint")
            button.close-popup.d-block.mx-auto.my-4.px-5.py-1.btn(@click="close") BEZÁRÁS
    button.help.d-block.btn(@click="help") segítség
</template>

<style scoped>
.help {
    padding: 1px 4px;
    color: #2B3737;
    background-color: #A1AAAA;
}
.hint {
    color: #ccc;
}
.small-logo {
    width: 148px;
    height: 41px;
    opacity: 0.8;
}
.close-popup {
    margin-bottom: 20px;
    color: #fff;
    background-color: #C19327;
}
</style>