<script>

import Dashboard from './Dashboard'
import Level1 from './levels/Level1'

export default {
    components: { Dashboard, Level1 },
    mounted()
    {
        this.email = localStorage.getItem('email')
    },
    methods: {
        setEmail(email) {
            localStorage.setItem('email', email)
            this.email = email
        },
        logout() {
            localStorage.removeItem('email')
            this.email = null
        }
    },
    data()
    {
        return {
            email: null,
        };
    },
};
</script>

<template lang="pug">
div
    dashboard(v-if="email" :email="email" @logout="logout")
    level1(v-else @setEmail="setEmail")
</template>

<style>
</style>