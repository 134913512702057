<script>

import axios from 'axios'
import Modal from '../Modal'
import HelpButton from '../HelpButton'

export default {
    name: 'level3',
    components: { Modal, HelpButton },
    props: {
        sublevel: Number,
        levelCompleted: Function,
        backToDashboard: Function
    },
    computed: {
        character() {
            return require('../img/whoami/' + (this.question_index + 1) + '.png')
        }
    },
    methods: {
        check() {
            const answer = this.answer.toLowerCase()
                .replaceAll('í', 'i').replaceAll('é', 'e').replaceAll('á', 'a').replaceAll('ű', 'u')
                .replaceAll('ő', 'o').replaceAll('ú', 'u').replaceAll('ö', 'o').replaceAll('ü', 'u').replaceAll('ó', 'o')

            if (answer.indexOf(this.questions[this.question_index].answer) === -1) {
                this.wrong_answer = true
                this.answer = ''
            } else {
                const email = localStorage.getItem('email')
                const sublevel = this.question_index === this.questions.length - 1 ? 0 : this.question_index + 1

                axios.post('/api/sublevel_completed', {'level': 3, 'email': email, 'sublevel': sublevel})
                this.correct_answer = true
                this.$emit('setSublevel', 3, (this.question_index + 1) % this.questions.length)
                if (this.question_index === this.questions.length - 1) {
                    this.$emit('levelCompleted')
                }
            }
        },
        goForward() {
            if (this.question_index < this.questions.length - 1) {
                this.question_index++
                this.correct_answer = false
                this.answer = ''
            } else {
                this.$emit('backToDashboard')
            }
        },
    },
    created() {
        this.question_index = this.sublevel
    },
    data()
    {
        return {
            question_index: 0,
            answer: '',
            correct_answer: false,
            wrong_answer: false,
            questions: [
                {
                    question: "Ez aztán a bor iránti szeretet,\nNem pancsolok, hanem keverek.",
                    answer: "evi",
                    motivation: "Küv Évi bármikor kever neked egy jó fröcsit."
                },
                {
                    question: "A szemedet bármikor felnyitom,\nA végső válasz a lexikon.",
                    answer: "pityu",
                    motivation: "Ha kérdésed lenne a fröccs történelméről, Pityu az embered."
                },
                {
                    question: "Sörnyitó, notesz és egy ceruza,\nEgy jó kocsmának kell egy kis regula.",
                    answer: "boske",
                    motivation: "Böske morcona külseje szerető szívet rejt."
                },
                {
                    question: "Egy jó fröccstől az ember csak beszédesebb,\nDe számomra egy huba buba ízű csók a legédesebb!",
                    answer: "bela",
                    motivation: "Melóka után Béla mindig bedob egy fröcsit."
                },
                {
                    question: "Egy nagyfröccs a napomhoz mindig hozzátesz,\nDe életem megoldása végül öt szám lesz.",
                    answer: "buzgomocsing",
                    motivation: "Buzgómócsing lesz a jövőheti lottó nyertes."
                }
            ]
        }
    },
}
</script>

<template lang="pug">
div
    .content-container.rounded
        .content
            .d-flex.justify-content-end
                .indicator.d-inline.rounded.py-1.px-3.m-3 {{question_index + 1}}/{{questions.length}}
            .d-flex.justify-content-center(v-if="correct_answer")
                .character(:style="{'background-image': 'url(' + character + ')'}")
                .motivation.d-flex.flex-column.justify-content-center
                    h1.text-center.light HELYES!
                    h2.text-center.light.font-italic {{questions[question_index].motivation}}
                    button.btn.big.pt-1.pb-0.mt-4.mb-5.d-inline-flex.align-items-center.mx-auto(@click="goForward") TOVÁBB
            .question-form.d-flex.flex-column.justify-content-between(v-else)
                h1.text-center.light KI VAGYOK ÉN?
                h2.question.text-center.light.font-italic "{{questions[question_index].question}}"
                .form
                    .text-center.light.mb-3.text-danger(v-if="wrong_answer") Ez nem jött össze. Próbáld újra!
                    input.w-100.p-2.border-0.rounded(type="text" v-model="answer" @keyup="wrong_answer=false" @keyup.enter="check")
                    .d-flex.justify-content-end
                        button.btn.pt-1.pb-0.mt-3(@click="check") MEHET
    .d-flex.justify-content-end
        help-button(video_id="qWnNpJX5K7A")
</template>

<style scoped>
.content-container {
    margin: 22px 0 14px 0;
    padding: 5px;
    background-color: #a0a0a0;
}
.content {
    height: 530px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../img/whoami/background.png');
}
.indicator {
    font-size: 20px;
    background-color: #a0a0a0;
}
.question-form {
    margin-top: 40px;
    height: 300px;
}
.question {
    white-space: pre;
}
.form {
    margin: 0 auto;
    width: 340px;
}
.character {
    width: 400px;
    height: 400px;
    background-repeat: no-repeat;
    background-size: contain;
}
.motivation {
    width: 350px;
}
.light {
    color: #D1DDDD;
}
.btn {
    color: #fff;
    background-color: #C19327;
}
.btn.big {
    font-size: 24px;
    padding: 16px 18px;
}
</style>