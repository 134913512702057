<script>

import Modal from '../Modal'
import HelpButton from '../HelpButton'

export default {
    name: 'level2',
    components: { Modal, HelpButton },
    props: {
        levelCompleted: Function,
        backToDashboard: Function
    },
    methods: {
        getRow(i) {
            return this.cards.slice(4 * (i - 1), 4 * i)
        },
        getImage(i) {
            return require('../img/memory/' + i + '.png')
        },
        isActive(card) {
            return this.active_cards.indexOf(card) !== -1
        },
        isRemoved(card) {
            return this.removed_cards.indexOf(card) !== -1
        },
        cardClicked(card) {
            if (!this.is_active || this.isRemoved(card))
                return

            const index = this.active_cards.indexOf(card)
            if (index !== -1) {
                this.active_cards = []
                return
            }

            if (this.active_cards.length === 2) {
                this.active_cards = []
            }
            this.active_cards.push(card)
            clearTimeout(this.turn_back_timeout)
            if (this.active_cards.length === 2 && (this.active_cards[0] % 10) === (this.active_cards[1] % 10)) {
                this.is_active = false
                setTimeout(() => {
                    this.removed_cards.push(this.active_cards[0])
                    this.removed_cards.push(this.active_cards[1])
                    this.active_cards = []
                    if (this.removed_cards.length === this.cards.length) {
                        this.endGame()
                    }
                    this.is_active = true
                }, 1000);
            } else if (this.active_cards.length === 2) {
                this.turn_back_timeout = setTimeout(() => {
                    this.active_cards = []
                }, 2000)
            }
        },
        goAhead() {
            this.$refs.modal.closeModal()
            this.$emit('backToDashboard')
        },
        endGame() {
            this.$refs.modal.openModal()
            this.$emit('levelCompleted')
        },
        getRandomInt(max) {
            max = Math.floor(max)
            return Math.floor(Math.random() * (max + 1))
        }
    },
    mounted() {
        this.cards = []
        this.active_cards = []
        this.removed_cards = []
        const all_cards = []
        for (var i = 0; i < 20; i++) {
            all_cards.push(i);
        }
        for (var i = 0; i < 20; i++) {
            const card = all_cards.splice(
                this.getRandomInt(all_cards.length - 1),
                1
            )[0];
            this.cards.push(card);
        }
        this.$refs.modal.closeModal()
    },
    data()
    {
        return {
            cards: [],
            active_cards: [],
            removed_cards: [],
            is_active: true,
            turn_back_timeout: null,
        }
    },
}
</script>

<template lang="pug">
div
    modal(ref="modal" closeOnClickOutside=false :width="375" :marginTop="300")
        .title.light.text-center GRATULÁLOK!
        .light.text-center A fröccs alapjaiból kitűnő a tudásod!
        .light.text-center Ha készen állsz, jöhet a következő szint!
        .py-4
            button.d-block.mx-auto.btn(@click="goAhead") TOVÁBBLÉPÉS
    .card-board
        .d-flex(v-for="row_index in 5" :key="row_index")
            .card.flex-grow-1(v-for="card in getRow(row_index)" :key="card" :class="{'active': isActive(card), 'removed': isRemoved(card)}" @click="cardClicked(card)")
                .card-inner
                    .card-front.rounded(:style="{'background-image': 'url(' + getImage(card) + ')'}")
                    .card-back.rounded
    .d-flex.justify-content-end.mt-3
        help-button(hint="Párban szép az élet.")
</template>

<style scoped>
.card-board {
    width: 920px;
    margin: 30px -10px 0 -10px;
}
.card {
    width: 300px;
    height: 100px;
    margin: 10px;
    perspective: 1000px;
    background: transparent;
    transition: opacity 1.2s;
    opacity: 1;
}
.card.removed {
    opacity: 0;
}
.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d;
}
.card.active .card-inner,
.card.removed .card-inner {
    transform: rotateY(180deg);
}
.card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 105%;
}
.card-front {
    transform: rotateY(180deg);
}
.card-back {
    background-image: url('../img/memory/back.png');
}
.title {
    font-size: 24px;
}
.light {
    color: #D1DDDD;
}
.btn {
    color: #fff;
    background-color: #C19327;
}
</style>