<script>

import axios from 'axios'
import Modal from '../Modal'
import HelpButton from '../HelpButton'

export default {
    name: 'level1',
    components: { Modal, HelpButton },
    methods: {
        keyup(index) {
            if (this.isSolved()) {
                this.askForEmail()
            }

            const number = this['number_' + index]
            if (number < '0' || number > '9') {
                this['number_' + index] = ''
            } else {
                if (index < 6) {
                    this.$refs['number_' + (index + 1)].focus()
                }
            }
        },
        focus(index) {
            this['number_' + index] = ''
        },
        enter() {
            if (this.validEmail(this.email)) {
                const self = this
                axios.post('/api/level_completed', {'level': 1, 'email': this.email, 'code': this.code})
                    .then(function (response) {
                        if (response.data === 'ok') {
                            self.$emit('setEmail', self.email)
                        }
                    })
            } else {
                this.invalid_email = true
            }
        },
        continueGame() {
            if (!this.validEmail(this.continue_email)) {
                this.shake = true
                setTimeout(() => this.shake = false, 500)
                return;
            }

            const self = this
            axios.get('/api/get', { params: {'email': this.continue_email} })
                .then(function (response) {
                    if (response.data !== 'invalid_email') {
                        self.$emit('setEmail', self.continue_email)
                    } else {
                        self.shake = true
                        setTimeout(() => self.shake = false, 500)
                    }
                })
        },
        isSolved() {
            var hash = 0
            var code = String('')
            for (var i = 1; i <= 6; i++) {
                const char = String(this['number_' + i]).charCodeAt(0)
                code += String(this['number_' + i])
                hash = ((hash<<5)-hash)+char
                hash = hash & hash
            }
            if (hash === 1598275519) {
                this.code = code
                return true
            }

            return false
        },
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email)
        },
        askForEmail() {
            this.$refs.modal.openModal()
        }
    },
    data()
    {
        return {
            code: '',
            email: '',
            continue_email: '',
            invalid_email: false,
            shake: false,
            number_1: '',
            number_2: '',
            number_3: '',
            number_4: '',
            number_5: '',
            number_6: '',
        }
    },
}
</script>

<template lang="pug">
div
    modal(ref="modal" :width=375 :marginTop=110 :closeOnClickOutside="false")
        .text-center.light.mb-4 Helyes kód! Belépés engedélyezve!
        input.d-block.m-auto.rounded.border-0.text-center.email(v-model="email" type="email" placeholder="Írd be az email címed!" autocomplete="off" @keyup.enter="enter")
        .mt-2.text-danger.text-center(v-if="invalid_email") Kérlek valódi email címed adj meg!
        button.d-block.mx-auto.my-3.px-5.py-1.btn.enter(@click="enter") BELÉPÉS
        .text-center.mt-5.light A valódi email címed kell megadni.
        .text-center.pb-4.mb-1.light Nem küldünk emaileket a beleegyezésed nélkül.
    div.content
        iframe.mt-5(width="800" height="450" src="https://www.youtube.com/embed/l6ly8wSlqGA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
        form.d-flex.justify-content-around.px-5(autocomplete="chrome-off" @submit.prevent="")
            input.number.rounded.border-0.text-center(v-model="number_1" type="text" maxlength="1" autocomplete="chrome-off" @keyup="keyup(1)" @focus="focus(1)" ref="number_1")
            input.number.rounded.border-0.text-center(v-model="number_2" type="text" maxlength="1" autocomplete="chrome-off" @keyup="keyup(2)" @focus="focus(2)" ref="number_2")
            input.number.rounded.border-0.text-center(v-model="number_3" type="text" maxlength="1" autocomplete="chrome-off" @keyup="keyup(3)" @focus="focus(3)" ref="number_3")
            input.number.rounded.border-0.text-center(v-model="number_4" type="text" maxlength="1" autocomplete="chrome-off" @keyup="keyup(4)" @focus="focus(4)" ref="number_4")
            input.number.rounded.border-0.text-center(v-model="number_5" type="text" maxlength="1" autocomplete="chrome-off" @keyup="keyup(5)" @focus="focus(5)" ref="number_5")
            input.number.rounded.border-0.text-center(v-model="number_6" type="text" maxlength="1" autocomplete="chrome-off" @keyup="keyup(6)" @focus="focus(6)" ref="number_6")
        .footer.rounded.d-flex.justify-content-center
            .text.light.text-nowrap.align-middle Ha már játékban vagy, és folytatni szeretnéd, írd be az email címed:
            form(autocomplete="off")
                input.continue-email.rounded.border-0(type="email" v-model="continue_email" :class="{shake: shake}" autocomplete="off" @keydown.enter.prevent="continueGame")
            button.d-block.btn(@click="continueGame") FOLYTATÁS
        .d-flex.justify-content-center.mt-3
            .footer-line.w-100.align-self-center
                a(href="/adatkezelesi_tajekoztato.pdf" target="_blank") Adatkezelés
                span &nbsp;&#8210;&nbsp;
                a(href="https://somashop.hu/product/froccs-tarsasjatek/") A fröccsről bővebben
                span &nbsp;&#8210;&nbsp;
                a(href="https://somashop.hu/") Soma Shop
                span &nbsp;&#8210;&nbsp;Minden jog fenntartva
            help-button(video_id="3iZ9nTUefUQ")
</template>

<style scoped>
.content {
    width: 800px;
    margin: auto;
    margin-bottom: 20px;
}
.number {
    width: 74px;
    margin-top: 50px;
    padding: 0;
    font-size: 96px;
    line-height: 120px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 600;
}
.footer {
    margin-top: 30px;
    padding: 16px;
    font-size: 14px;
    background-color: #2B3737;
}
.footer .text {
    padding: 4px 0;
}
.footer input {
    width: 200px;
    margin: 0 12px;
    padding: 2px 6px;
    background-color: #A1AAAA;
}
.footer .btn {
    padding: 2px 8px;
    font-size: 12px;
}
.email {
    width: 70%;
    padding: 4px;
    background-color: #A1AAAA;
}
.btn {
    color: #fff;
    background-color: #C19327;
}
.light {
    color: #ccc;
}
.continue-email.shake {
  animation: shake 0.5s;
}
.footer-line {
    margin-left: 67px;
    text-align: center;
}

.footer-line a,
.footer-line span {
    font-size: 12px;
    color: #ccc;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
</style>