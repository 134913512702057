<script>

import axios from 'axios'
import Modal from './Modal'
import Level2 from './levels/Level2.vue'
import Level3 from './levels/Level3.vue'
import Level4 from './levels/Level4.vue'
import Level5 from './levels/Level5.vue'
import Level6 from './levels/Level6.vue'

export default {
    name: 'dashboard',
    components: { Modal, Level2, Level3, Level4, Level5, Level6 },
    props: {
        email: String,
    },
    methods: {
        logout() {
            this.$emit('logout')
        },
        openModal(index) {
            this.$refs['modal_' + index].openModal()
        },
        openLevel(level) {
            this.level = level
        },
        backToDashboard() {
            this.level = null
        },
        levelCompleted(level, extra_params) {
            var params = {'level': level, 'email': this.email}
            if (extra_params) {
                params = Object.assign(params, extra_params)
            }
            const self = this
            axios.post('/api/level_completed', params)
                .then((response) => {
                    if (response.data === 'ok') {
                        self.max_level = Math.max(self.max_level, level)
                    }
                })
        },
        setSublevel(level, sublevel) {
            if (level === 3)
                this.sublevel_3 = sublevel
            if (level === 4)
                this.sublevel_4 = sublevel
        },
        showHeader() {
            this.show_header = true
        },
        hideHeader() {
            this.show_header = false
        },
    },
    mounted() {
        const self = this
        this.loading = true
        axios.get('/api/get', { params: {'email': this.email} })
            .then(function (response) {
                if (response.data !== 'invalid_email') {
                    self.max_level = response.data.level
                    self.sublevel_3 = response.data.sublevel_3
                    self.sublevel_4 = response.data.sublevel_4
                } else {
                    self.$emit('logout')
                }
                self.loading = false
            })
    },
    data() {
        return {
            loading: true,
            max_level: 0,
            sublevel_3: 0,
            sublevel_4: 0,
            level: null,
            show_header: true,
        }
    }
}

</script>

<template lang="pug">
    h1.loading.text-center(v-if="loading") Töltődik a Fröccs...
    div(v-else)
        modal(ref="modal_1" :showLogo="false")
            div
                .modal-content.rounded.mt-5
                    h2.text-center.py-3
                        | Vörösmarty Mihály: FÓTI DAL
                    pre.
                        Fölfelé megy borban a gyöngy; Jól teszi.
                        Tőle senki e jogát el Nem veszi.
                        Törjön is mind ég felé az Ami gyöngy;
                        Hadd maradjon gyáva földön A göröngy.
                    pre.
                        Testet éleszt és táplál a Lakoma,
                        De ami a lelket adja, Az bora.
                        Lélek és bor két atyafi Gyermekek;
                        Hol van a hal, mely dicső volt És remek?
                    pre.
                        Víg pohár közt édesebb a Szerelem.
                        Ami benne keserű van, Elnyelem.
                        Hejh galambom, szőke bimbóm, Mit nevetsz?
                        Áldjon meg a három isten, Ha szeretsz.
                    pre.
                        Érted csillog e pohár bor, Érted vív,
                        Tele tűzzel, tele lánggal, Mint e szív;
                        Volna szívem, felszökelne Mint a kút,
                        Venni tőled vagy szerelmet, Vagy bucsút.
                    pre.
                        ...
                    pre.
                        1842 október 5.
                .d-flex.justify-content-between.mt-4.mb-5.mx-auto(:style="{width: '600px'}")
                    img.small-logo(src="./img/logo_dark.png")
                    div
                        button.btn.close-popup(@click="$refs.modal_1.closeModal()") BEZÁRÁS
        modal(ref="modal_2" :showLogo="false")
            div
                iframe.mt-5.d-block.mx-auto(width="560" height="315" src="https://www.youtube.com/embed/RejJK_Ey4O0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
                .d-flex.justify-content-between.mt-4.mb-5.mx-auto(:style="{width: '560px'}")
                    img.small-logo(src="./img/logo_dark.png")
                    div
                        button.btn.close-popup(@click="$refs.modal_2.closeModal()") BEZÁRÁS
        modal(ref="modal_3" :showLogo="false")
            div
                img.photo.mt-5.rounded(src="./img/polaroid.jpg")
                .d-flex.justify-content-between.mt-4.mb-5.mx-auto(:style="{width: '600px'}")
                    img.small-logo(src="./img/logo_dark.png")
                    div
                        button.btn.close-popup(@click="$refs.modal_3.closeModal()") BEZÁRÁS
        modal(ref="modal_4" :showLogo="false")
            div
                iframe.mt-5.d-block.mx-auto(width="560" height="315" src="https://www.youtube.com/embed/-j8q5ZUGiBU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
                .d-flex.justify-content-between.mt-4.mb-5.mx-auto(:style="{width: '560px'}")
                    img.small-logo(src="./img/logo_dark.png")
                    div
                        button.btn.close-popup(@click="$refs.modal_4.closeModal()") BEZÁRÁS
        .content
            .header.d-flex(v-if="show_header")
                .logo.section.rounded-left(role="button" @click="backToDashboard")
                .section.flex-grow-1.d-flex.justify-content-center
                    .subject(:class="{'active': max_level >= 2 }" @click="max_level >= 2 ? openModal(1) : null")
                    .subject(:class="{'active': max_level >= 5 }" @click="max_level >= 5 ? openModal(2) : null")
                    .subject(:class="{'active': max_level >= 4 }" @click="max_level >= 4 ? openModal(3) : null")
                    .subject(:class="{'active': max_level >= 3 }" @click="max_level >= 3 ? openModal(4) : null")
                .right-section.section.d-flex.flex-column.justify-content-center.align-items-center.rounded-right
                    .text-center {{email}}
                    button.d-block.px-2.btn.enter(@click="logout") KILÉPÉS
            template(v-if="!level")
                .d-flex.justify-content-center
                    .board.board-1(:class="{'active': max_level >= 1 }" @click="max_level >= 1 ? openLevel(2) : null")
                    .board.board-2(:class="{'active': max_level >= 2 }" @click="max_level >= 2 ? openLevel(3) : null")
                    .board.board-3(:class="{'active': max_level >= 3 }" @click="max_level >= 3 ? openLevel(4) : null")
                .d-flex.justify-content-center.mb-5
                    .board.board-4(:class="{'active': max_level >= 4 }" @click="max_level >= 4 ? openLevel(5) : null")
                    .board.board-5(:class="{'active': max_level >= 5 }" @click="max_level >= 5 ? openLevel(6) : null")
            Level2(v-if="level==2" @backToDashboard="backToDashboard" @levelCompleted="(extra_params) => levelCompleted(2, extra_params)")
            Level3(v-if="level==3" :sublevel="sublevel_3" @backToDashboard="backToDashboard" @levelCompleted="(extra_params) => levelCompleted(3, extra_params)" @setSublevel="setSublevel")
            Level4(v-if="level==4" :sublevel="sublevel_4" @backToDashboard="backToDashboard" @levelCompleted="(extra_params) => levelCompleted(4, extra_params)" @setSublevel="setSublevel")
            Level5(v-if="level==5" @backToDashboard="backToDashboard" @levelCompleted="(extra_params) => levelCompleted(5, extra_params)")
            Level6(v-if="level==6" @backToDashboard="backToDashboard" @levelCompleted="(extra_params) => levelCompleted(6, extra_params)" @showHeader="showHeader" @hideHeader="hideHeader")
</template>

<style scoped>
.loading {
    margin-top: 40vh;
    color: #A1AAAA;
}
.content {
    width: 900px;
    margin: auto;
    margin-bottom: 20px;
}
.header {
    margin-top: 60px;
    height: 130px;
}
.logo {
    width: 280px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
    background-image: url('./img/logo_dark.png');
}
.subject {
    height: 130px;
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
}
.subject:nth-child(1) {
    width: 64px;
    background-image: url('./img/header/1_inactive.png');
}
.subject:nth-child(2) {
    width: 120px;
    background-image: url('./img/header/2_inactive.png');
}
.subject:nth-child(3) {
    width: 96px;
    background-image: url('./img/header/3_inactive.png');
}
.subject:nth-child(4) {
    width: 124px;
    background-image: url('./img/header/4_inactive.png');
}
.subject.active:nth-child(1) {
    background-image: url('./img/header/1.png');
}
.subject.active:nth-child(2) {
    background-image: url('./img/header/2.png');
}
.subject.active:nth-child(3) {
    background-image: url('./img/header/3.png');
}
.subject.active:nth-child(4) {
    background-image: url('./img/header/4.png');
}
.subject.active {
    cursor: pointer;
}
.section {
   background-color: #A1AAAA90;
}
.section:nth-child(2) {
    margin: 0 6px;
}
.right-section {
    width: 200px;
}
.btn {
    width: 90px;
    padding: 1px;
    margin-top: 6px;
    color: #A1AAAA;
    background-color: #373839;
}
.board {
    width: 300px;
    height: 300px;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
}
.board.active {
    cursor: pointer;
}
.board.board-1 {
    background-image: url('./img/board/1_inactive.png');
}
.board.board-2 {
    background-image: url('./img/board/2_inactive.png');
}
.board.board-3 {
    background-image: url('./img/board/3_inactive.png');
}
.board.board-4 {
    background-image: url('./img/board/4_inactive.png');
}
.board.board-5 {
    background-image: url('./img/board/5_inactive.png');
}
.board.board-1.active {
    background-image: url('./img/board/1.png');
}
.board.board-2.active {
    background-image: url('./img/board/2.png');
}
.board.board-3.active {
    background-image: url('./img/board/3.png');
}
.board.board-4.active {
    background-image: url('./img/board/4.png');
}
.board.board-5.active {
    background-image: url('./img/board/5.png');
}
.modal-content {
    width: 600px;
    margin: 0px auto;
    padding: 24px;
    background-color: #8AA79E;
}
.btn.close-popup {
    margin: auto;
    width: auto;
    padding: 4px 30px;
    color: #fff;
    background-color: #C19327;
}
.photo {
    display: block;
    width: 600px;
    height: 500px;
    margin: 0 auto 30px auto;
}
.small-logo {
    width: 148px;
    height: 41px;
    opacity: 0.8;
}
</style>
