<script>

export default {
    name: 'modal',
    props: {
        closeOnClickOutside: {
            type: Boolean,
            default: true
        },
        width: {
            type: Number,
            default: 700
        },
        marginTop: {
            type: Number,
            default: 40
        },
        showLogo: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        openModal() {
            this.open = true
            this.$nextTick(() => {
                const rect = document.querySelector('html').getBoundingClientRect()
                this.height = rect.bottom - rect.top
            })
        },
        closeModal() {
            this.open = false
        },
        clickedOutside() {
            if (this.closeOnClickOutside) {
                this.closeModal()
            }
        }
    },
    data() {
        return {
            open: false,
            height: 0,
        }
    }
}

</script>

<template lang="pug">
    .d-block.modal(v-if="open" @click="clickedOutside" :style="{'height': height + 'px'}")
        .d-block.window(@click.stop :style="{'width': width + 'px', 'marginTop': marginTop + 'px'}")
            img.w-100.logo(src="./img/logo_dark.png" v-if="showLogo")
            slot
</template>

<style scoped>
.modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    background-color: #000000a0;
}
.window {
    margin: auto;
    background-color: #2B3737;
    border: solid 3px #aaa;
    border-radius: 8px;
}
.logo {
    padding: 10% 20%;
    opacity: 0.6;
}
</style>