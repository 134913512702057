import Vue from 'vue'
import App from './App.vue'
import LazyLoad from 'vue-lazyload'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './css/app.css';

Vue.use(LazyLoad)

new Vue({
    render: h => h(App)
}).$mount('#app')
