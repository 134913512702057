<script>

import Modal from '../Modal'
import HelpButton from '../HelpButton'

export default {
    name: 'level5',
    components: { Modal, HelpButton },
    props: {
        levelCompleted: Function,
        backToDashboard: Function
    },
    methods: {
        getButton(name) {
            return 'url(' + require('../img/phone/' + name + '.png') + ')';
        },
        numberClicked(number) {
            if (this.last_sequence.length === 6) {
                this.last_sequence = this.last_sequence.substr(1)
            }
            this.last_sequence += String(number)

            if (this.hash(this.last_sequence) === 1448875322) {
                this.$emit('levelCompleted', {code: this.last_sequence})
                this.$refs.modal.openModal()
            }
        },
        goForward() {
            this.$emit('backToDashboard')
        },
        hash(str) {
            var hash = 0
            for (var i = 0; i < str.length; i++) {
                const char = str.charCodeAt(i)
                hash = ((hash<<5)-hash)+char
                hash = hash & hash
            }
            return hash
        },
        playClickSound() {
            this.click_sound.play()
        }
    },
    mounted() {
        this.click_sound = new Audio(require('../sound/click.mp3'))
    },
    data()
    {
        return {
            last_sequence: '',
            click_sound: null,
        }
    },
}
</script>

<template lang="pug">
div
    modal(ref="modal" :width="420" :marginTop="300")
        h2.text-center.light GRATULÁLOK!
        .text-center.light Látod, több dologra is alkalmas lehet a játékszabály...
        .text-center.light Ha készen állsz, jöhet az utolsó kihívás!
        button.btn.big.py-0.mt-5.mb-4.d-block.mx-auto(@click="goForward") TOVÁBBLÉPÉS
        .pb-2
    .content.mt-4.d-flex.rounded
        .numbers
            .d-flex.justify-content-around
                button(:style="{'background-image': getButton('1')}" @click="numberClicked(1)" @mousedown="playClickSound")
                button(:style="{'background-image': getButton('2')}" @click="numberClicked(2)" @mousedown="playClickSound")
                button(:style="{'background-image': getButton('3')}" @click="numberClicked(3)" @mousedown="playClickSound")
            .d-flex.justify-content-around
                button(:style="{'background-image': getButton('4')}" @click="numberClicked(4)" @mousedown="playClickSound")
                button(:style="{'background-image': getButton('5')}" @click="numberClicked(5)" @mousedown="playClickSound")
                button(:style="{'background-image': getButton('6')}" @click="numberClicked(6)" @mousedown="playClickSound")
            .d-flex.justify-content-around
                button(:style="{'background-image': getButton('7')}" @click="numberClicked(7)" @mousedown="playClickSound")
                button(:style="{'background-image': getButton('8')}" @click="numberClicked(8)" @mousedown="playClickSound")
                button(:style="{'background-image': getButton('9')}" @click="numberClicked(9)" @mousedown="playClickSound")
            .d-flex.justify-content-around
                button(:style="{'background-image': getButton('star')}" @mousedown="playClickSound")
                button(:style="{'background-image': getButton('0')}" @click="numberClicked(0)" @mousedown="playClickSound")
                button(:style="{'background-image': getButton('hashmark')}" @mousedown="playClickSound")
        .bottles
    .d-flex.justify-content-end.mt-3
        help-button(video_id="ebVkeYClh_c")
</template>

<style scoped>
h2 {
    font-size: 22px;
}
.content {
    height: 540px;
    background-color: #F8AD20;
}
.numbers {
    width: 450px;
    padding: 80px 60px;
}
.numbers button {
    border: none;
    width: 80px;
    height: 80px;
    margin-bottom: 22px;
    outline: none;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}
.numbers button:active {
    background-size: 96%;
}
.bottles {
    width: 450px;
    height: 500px;
    margin-top: 40px;
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: left center;
    background-image: url('../img/phone/bottles.png');
}
.light {
    color: #D1DDDD;
}
.btn {
    color: #fff;
    background-color: #C19327;
    font-size: 24px;
    padding: 16px 18px;
}
</style>