<script>

import Vue from 'vue'
import Modal from '../Modal'
import HelpButton from '../HelpButton'

export default {
    name: 'level6',
    components: { Modal, HelpButton },
    methods: {
        select(question_index, answer_index) {
            Vue.set(this.selected_answers, question_index, answer_index)
            const code = String(this.selected_answers[0])
                + String(this.selected_answers[1])
                + String(this.selected_answers[2])
                + String(this.selected_answers[3])

            if (this.hash(code) === 1512233) {
                this.$emit('levelCompleted', {code: code})
                this.$emit('hideHeader')
                this.completed = true
            }
        },
        isSelected(question_index, answer_index) {
            return this.selected_answers[question_index] == answer_index
        },
        goToDashboard() {
            this.$emit('showHeader')
            this.$emit('backToDashboard')
        },
        hash(str) {
            var hash = 0
            for (var i = 0; i < str.length; i++) {
                const char = str.charCodeAt(i)
                hash = ((hash<<5)-hash)+char
                hash = hash & hash
            }
            return hash
        }
    },
    mounted() {
        this.selected_answers = [null, null, null, null]
    },
    data()
    {
        return {
            selected_answers: [null, null, null, null],
            completed: false,
            options: [
                ['4.29', '10.05', '12.21', '9.18', '6.06', '1.15', '12.14', '9.07', '4.23', '9.30'],
                ['egy', 'kettő', 'három', 'négy', 'öt', 'hat', 'hét', 'nyolc', 'kilenc', 'tíz'],
                ['Szekszárd', 'Villány', 'Csopak', 'Eger', 'Badacsony', 'Tokaj', 'Etyek', 'Somló', 'Sopron', 'Lenne'],
                ['Kossuth', 'Petőfi', 'Toldi', 'Döbrögi', 'Örkény', 'Krúdy', 'Ady', 'Piszkos', 'Hufnágel', 'Vörösmarty'],
            ]
        }
    },
}
</script>

<template lang="pug">
div
    .completed(v-if="completed")
        h1.text-center.mt-5 GRATULA!
        h1.text-center KIHÍVÁSOK TELJESÍTVE!
        iframe.mt-4.d-block.mx-auto(width="560" height="315" src="https://www.youtube.com/embed/HNpwSOQHck4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
        .d-flex.justify-content-end
            button.btn.big.d-block(@click="goToDashboard") VISSZA
    .mt-4(v-else)
        .d-flex.justify-content-between
            .header.rounded MIKOR?
            .header.rounded HÁNYAT?
            .header.rounded HONNAN?
            .header.rounded KIVEL?
        .d-flex.justify-content-between(v-for="answer_index in options[0].length" :key="answer_index")
            .button.rounded(v-for="question_index in options.length" :key="question_index" role="button" :class="{'selected': isSelected(question_index - 1, answer_index - 1)}" @click="select(question_index - 1, answer_index - 1)")
                | {{options[question_index - 1][answer_index - 1]}}
        .d-flex.justify-content-end.pb-4
            help-button(hint="Megvizsgáltad a tárgyakat, amiket a játék során megszereztél?")
</template>

<style scoped>
h1 {
    font-size: 44px;
    color: #D1DDDD;
}
.header,
.button {
    width: 200px;
    padding: 2px 0;
    margin-bottom: 18px;
    font-size: 34px;
    text-align: center;
}
.header {
    color: #D1DDDD;
    background-color: #497C6B;
}
.button {
    background-color: #B4DBCE;
}
.button.selected {
    background-color: #C59E26;
}
.btn.big {
    margin-top: 30px;
    margin-right: 170px;
    padding: 4px 30px;
    color: #D1DDDD;
    background-color: #C59E26;
}
</style>